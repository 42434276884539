import Navbar from "./Misc/Navbar";
import FrontPage from "./Homepages/FrontPage";
import ContactPage from "./Contact/ContactPage";
import AboutUs from "./AboutUs/AboutUs";
import Footer from "./Misc/Footer";
import ChatBot from "./Misc/ChatBot";
import Packages from "./Packages/Packages";

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import PageNotFound from "./Misc/PageNotFound";

function App() {
  return (
    <Router>
      <div>
        <Navbar></Navbar>
        <div className="content min-h-screen">
          <Routes>
            <Route path="/" element={<FrontPage></FrontPage>}></Route>
            <Route path="/contact" element={<ContactPage></ContactPage>}></Route>
            <Route path="*" element={<PageNotFound></PageNotFound>}></Route>
            <Route path="/about" element={<AboutUs></AboutUs>}></Route>
            <Route path="/packages" element={<Packages></Packages>}></Route>
          </Routes>
        </div>
        <Footer className="mt-20"></Footer>
        <ChatBot></ChatBot>
      </div>
    </Router>
    
  );
}

export default App;
