function StatementSection() {
    return (
            <div className='overflow-x-hidden w-[92%] md:w-[60%] m-auto text-xl flex flex-col md:flex-row mb-20 md:mb-56 gap-10 md:gap-0'>
                <div className={"flex-1"}>
                    <p className="text-2xl md:text-4xl capitalize  font-semibold" ><span className={"text-3xl md:text-5xl text-blue-300"}>$0</span> down<br />  12 months Min contract</p>
                    <p className={"my-10 opacity-75 text-lg w-96"}>For a 5 pages website, lightning fast gorgeous website that is custom-coded. Contents, listings and domains belong 100% to you. Cancel anytime with zero hassle. Custom prices available on request depends on the scope and length of the project  </p>
                    <p className={"uppercase text-blue-300 mb-3"}>included</p>
                    <ul className={"ml-5 capitalize leading-10"}>
                        <li>
                            Hosting fees
                        </li>
                        <li>
                            unlimited edits
                        </li>
                        <li>
                            lifetime services / updates
                        </li>
                        <li>
                            24/7 chat support
                        </li>
                        <li>
                            Graphics Design
                        </li>
                        <li>

                        </li>
                    </ul>

                </div>
                <div className={"flex md:justify-center pl-4 md:pl-0 items-center flex-1 text-3xl md:text-5xl text-blue-300"}>
                    <div>
                        <div className={""}>
                            Price:
                        </div>
                        <div className={"my-4"}>
                            149$/mo
                        </div>
                        <div className={""}>
                            299$/mo
                        </div>
                    </div>

                </div>
            </div>
    )
}

export default StatementSection