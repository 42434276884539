import TechBox from "./Statement/TechBox";

function CustomSection(){
    return(
        <div>
            <div className='flex justify-center text-center mb-20 md:mb-56'>
                <div>
                    <p className=" text-white  text-2xl md:text-3xl font-semibold uppercase" >why go <span className={"text-blue-300"}>custom</span> ?</p>
                    <p className={"my-10 w-[80%] md:w-[50%] m-auto text-lg opacity-75"}>
                        At Joe's Studio, we aim to shatter the notion that custom websites are reserved for the big companies. With our affordable monthly payment model, you're not getting just the website, you are also getting dedicated 24/7 service from our professionals
                    </p>
                    <div className="flex flex-col md:flex-row items-center md:items-start m-auto  justify-center gap-5 lg:gap-4">
                        <TechBox>
                            <p className={"text-4xl text-blue-300"}>1</p>
                            <p className={"font-medium"}>Loading Speed</p>
                            <p className={"text-base mt-2"}>
                                Custom websites load much faster (up to 10 times) than dynamic sites like WordPress, reducing user abandonment and improving the overall user experience.</p>
                        </TechBox>
                        <TechBox>
                            <p className={"text-4xl text-blue-300"}>2</p>
                            <p className={"font-medium"}>Optimization and SEO</p>
                            <p className={"text-base mt-2"}> Custom sites are well-optimized for mobile with a mobile-first approach, benefiting their search ranking compared to less mobile-optimized dynamic sites like WordPress.</p>
                        </TechBox>
                        <TechBox>
                            <p className={"text-4xl text-blue-300"}>3</p>
                            <p className={"font-medium"}>Investment in Quality</p>
                            <p className={"text-base mt-2"}>While WordPress or Wix offer convenience, professionally crafted custom sites offer superior long-term value in speed, security, customization, and user experience.</p>
                        </TechBox>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomSection