import IntroSection from './IntroSection.js';
import StatementSection from './Statement/StatementSection.js';
import ProductIntro from "./ProductIntro";
import CustomSection from "./CustomSection";
function FrontPage() {
    return (
        <div className="front-page">
            <IntroSection></IntroSection>
            <StatementSection></StatementSection>
            <ProductIntro></ProductIntro>
            <CustomSection></CustomSection>
        </div>
    )
}
export default FrontPage;
