import React from 'react';

function PageNotFound() {
  return (
    <div className='text-center'>
      <div className='mt-20 mb-72 md:mb-20 md:mt-40'>
        <h1 className='text-5xl md:text-8xl'>404!</h1>
        <p className='text-xl md:text-3xl '>Oops page not found</p>
      </div>
      <a href='/' className=" w-70 text-white  px-3 py-2 text-lg border border-white">Go To Home</a>
    </div>
  )
}
export default PageNotFound;