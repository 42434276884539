import { TypeAnimation } from "react-type-animation";
import Contact from "../Contact/Contact";

function IntroSection() {
    return (
        <div className="section">
            <div className="min-h-screen overflow-x-hidden flex justify-center items-center pl-10 md:px-10 md:py-10 xl:p-20 bg-gradient-to-b from-gray-900 via-gray-950 to-black" >
                <div className=" intro-section">
                    <div className="heading text-3xl lg:text-6xl font-bold">We Create & Design<br></br><span className={"text-blue-300"}> Amazing</span> <TypeAnimation
                        sequence={[
                            // Same substring at the start will only be typed out once, initially
                            'Web Brands ✨',
                            1500,
                            'Websites 🌐',
                            1500,
                            'E-commerces 🛍️',
                            1500
                        ]}
                        wrapper="span"
                        speed={50}
                        style={{ display: 'inline-block' }}
                        repeat={Infinity}
                    /></div>
                    <div className="mt-5 mb-10 w-1/2 lg:text-xl">
                        <p className="capitalize my-4 text-xl lg:text-2xl">from <span className="font-medium text-2xl lg:text-3xl">149$/Mo</span></p>
                        <p className="mb-4">

                            Experience fully hand-coded websites, delivering unparalleled results. Say goodbye to page builders and WordPress.<br></br>
                        </p>
                        <ul className="list-disc ml-10">
                            <li>
                                Loads at <span className={" font-semibold text-lg lg:text-2xl"}>Lightning</span> speed
                            </li>
                            <li>
                               Advance <span className={"font-semibold text-lg lg:text-2xl"}>Search Results</span> optimized
                            </li>
                            <li>
                                <span className={"font-semibold text-lg lg:text-2xl"}>Mobile-First</span> methodology
                            </li>
                            <li>
                                <span className={"font-semibold text-lg lg:text-2xl"}>Unlimited</span> edits
                            </li>
                        </ul>
                    </div>
                    <a href="/contact" className="px-3 py-3 text-base lg:text-xl transition border border-blue-300 font-medium">Get In Touch !</a>
                </div>
                <Contact className="hidden md:block w-[50%] xl:w-[23%] px-3 py-4 xl:px-5 xl:py-10 h-1/2" header="Get A Free Quotation"></Contact>
            </div>
        </div >
    );
}

export default IntroSection;