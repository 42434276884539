import { useEffect, useState } from "react"
import Cookies from 'js-cookie';
import { firestore } from "../Firebase/config";
import { collection, getDocs } from "firebase/firestore";

function Packages() {
    const [btnnum, setBtnnum] = useState(Cookies.get('package') || 1);
    const [packages, setPackages] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchPackages();
    }, [])

    function handleToggle(num) {
        setBtnnum(num);
        Cookies.set('package', num, { expires: 7 });
    }

    async function fetchPackages() {
        await getDocs(collection(firestore, "packages")).then((querySnapshot) => {
            const data = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
            setPackages([...data]);
            setLoading(false);

        })
    }
    function TypeBtn(props) {
        return (
            <div className={"cursor-pointer  flex-1 p-1  bg-gray-900  capitalize border-gray-700 border hover:border-blue-300 transition duration-2000 backdrop-blur-xl " + props.className + " " + props.btn} onClick={() => { handleToggle(props.btn) }}>
                {props.children}
            </div>
        )
    }

    function Pkgs() {
        const grouped = []
        const groupSize = 3
        const pkgs = packages.filter((pkg) => pkg.type == btnnum)

        for (let i = 0; i < pkgs.length; i += groupSize) {
            grouped.push(pkgs.slice(i, i + groupSize))
        }

        return (
            <div className={"mb-20"}>
                {loading ? <div className="text-center text-2xl mt-20">Loading...</div> :
                    grouped.map((groups, index) => (
                        <PkgBox pkgs={groups} key={index}></PkgBox>
                    ))
                }
            </div>
        )
    }

    function PkgBox({ pkgs }) {
        return (
            <div className="flex flex-col lg:flex-row mb-8 justify-center gap-5 lg:gap-8">
                {
                    pkgs.map((pkg) => {
                        return (
                            <div className="bg-gray-900 border-2 border-blue-300 px-5 py-8 text-start backdrop-blur-xl lg:basis-96 relative ">
                                <div className="text-3xl mb-4 capitalize font-medium text-center ">{pkg.name}</div>
                                <p className="mb-2 text-lg h-14 text-center uppercase font-medium">
                                    {pkg.page}
                                </p>
                                <div className="overflow-y-scroll h-56 border border-gray-600 p-4 ">
                                    <ul className="list-disc text-xl leading-10 capitalize">
                                        {
                                            pkg.feature.map((featur) => {
                                                return (
                                                    <li className="ml-4">
                                                        {featur}
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                                <p className="text-base text-center opacity-75 mt-1 mb-32">Features</p>
                                <div className=" absolute bottom-10 left-4 right-4">
                                    <div className=" border-b mx-5 my-5 border-white " ></div>
                                    {
                                        isNaN(pkg.price) ? (
                                            <div className="text-3xl text-center mb-5">
                                                <a href="/contact" className="opacity-60 hover:opacity-100">Contact Us</a>
                                            </div>
                                        )
                                            :
                                            (
                                                <>
                                                    <div className="text-3xl text-center mb-5">
                                                        <span className={"text-base"}>From</span> <span className={"text-5xl font-medium text-blue-300"}>{pkg.price - 1}</span>${
                                                        pkg.name.includes("a") ? <span>/mo</span> : <span></span>
                                                    }
                                                    </div>
                                                </>
                                            )
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    return (
        <div className="mt-20 mx-9 lg:mx-20 xl:mx-48 2xl:mx-72 text-center">
            <header className="heading text-white text-5xl py-10">Packages</header>
            <p className="text-lg md:text-xl" > Here are our packages, with estimation for your convenience </p>
            <div className="text-xl mt-10">
                <p>See packages for</p>
            </div>
            <div className=" text-sm font-medium inline-flex mt-8 mb-20 w-80 md:w-96">
                <TypeBtn btn={0} >E-commerce</TypeBtn>
                <TypeBtn btn={1} className="mx-4">Web</TypeBtn>
                <TypeBtn btn={2}>Bespoke</TypeBtn>
            </div>
            <Pkgs></Pkgs>
            <a
                className=" text-center px-4 py-4 text-xl font-medium border border-blue-300"
                href="/contact"
            >
                Contact Us Now !
            </a>
            <p className={"mt-20 text-lg capitalize opacity-75"}>Note: All subscription Services comes with a minimum contract of 12 months</p>
        </div>
    )
}



export default Packages