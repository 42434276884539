import fivestars from "../Media/5stars.png"

function ClientReview(props) {
    return (
        <div className="bg-gray-900 border-2 border-blue-300 p-5 text-start backdrop-blur-xl flex-1">
            <h1 className="text-3xl mb-4 font-semibold capitalize">{props.header}</h1>
            <div className="text-xl mb-4">
                <div className="review my-0 w-10/12"> {props.children} </div>
            </div>
            <img src={fivestars} className="h-4 block mb-4" alt={"five stars"}/>
            <div className="text-xl">
                {props.name}
            </div>
        </div>
    )
}

export default ClientReview