import Contact from "./Contact";
import {Socials} from "../Misc/Footer";


function ContactPage() {
    return (
        <div className="mt-28 mx-9 lg:mx-20 xl:mx-48 2xl:mx-72">
            <h1 className="heading text-5xl text-center ">Contact Us</h1>
            <div className="mt-16 border-white p-0 md:py-8 justify-center gap-20 flex flex-col lg:flex-row">
                <div className="flex-1">
                    <p className="text-2xl mb-4 capitalize font-light">
                        for any questions or inquiries
                    </p>
                    <h1 className="text-4xl heading">
                        Don't hesitate to get in touch
                    </h1>
                    <ul className="my-8 text-xl">
                        <li>
                            Tel: <a className="underline" href="tel:514-802-3161">514-802-3161</a>
                        </li>
                        <li>
                        Email: <a className="underline" href="mailto:sales@montrealwebstop.com">sales@montrealwebstop.com</a>
                        </li>
                    </ul>
                    <p className="text-lg mb-2">
                    Our Social Medias
                    </p>
                    <div><Socials></Socials></div>
                </div>
                <Contact header="Let Us Know !" className="flex-1 py-10 px-4 capitalize"></Contact>
            </div>
        </div>
    );
}

export default ContactPage; 