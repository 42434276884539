
import TechBox from './TechBox'


function StatementSection() {
    return (
            <div className='flex justify-center text-center mb-20 md:mb-56'>
                <div>
                    <p className="2xl:mt-56 sm:mt-40 mt-20 text-white  text-2xl md:text-3xl font-semibold uppercase" >focus on what <span className={"text-blue-300"}>you</span> do best</p>
                    <p className={"my-10 w-[80%] md:w-[50%] m-auto text-lg opacity-75"}>
                        We're a team of developer specialized in web development for businesses. Our web product are custom written, tailored to each and every single one of our clients. This will help your business to rank up on the search engine thus bring a lot more clients to your door.
                    </p>
                    <div className="flex items-center md:items-start flex-col md:flex-row m-auto justify-center gap-5 lg:gap-4">
                        <TechBox>
                            <svg className="block m-auto my-2 h-10 w-10 text-orange-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">  <rect x="5" y="2" width="14" height="20" rx="2" ry="2" />  <line x1="12" y1="18" x2="12.01" y2="18" /></svg>
                            <p className={"font-medium"}>Mobile Responsive</p>
                            <p className={"text-base mt-2"}>All sites will be made tailored for mobile devices, then will be ported to tablet and desktop</p>
                        </TechBox>
                        <TechBox>
                            <svg className="block m-auto my-2 h-10 w-10 text-green-400" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">  <polyline points="23 6 13.5 15.5 8.5 10.5 1 18" />  <polyline points="17 6 23 6 23 12" /></svg>
                            <p className={"font-medium"}>SEO Optimized</p>
                            <p className={"text-base mt-2"}>We ensure your website reaches your desired audience. At an amazing rate</p>
                        </TechBox>
                        <TechBox>
                            <svg className="block m-auto my-2 h-10 w-10 text-blue-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">  <polyline points="8 17 12 21 16 17" />  <line x1="12" y1="12" x2="12" y2="21" />  <path d="M20.88 18.09A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.29" /></svg>
                            <p className={"font-medium"}>Hosting included</p>
                            <p className={"text-base mt-2"}>Hosting is on us! Stop worrying about server maintenance or downtime</p>
                        </TechBox>
                    </div>
                </div>
            </div>
    )
}

export default StatementSection