import React from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import AutoPlay from 'embla-carousel-autoplay'
import ClientReview from '../AboutUs/ClientReview'

export const AboutUsCarousel = () => {
  const [emblaRef] = useEmblaCarousel({ loop: true }, [AutoPlay()])

  return (
      <div>
        <div className='flex flex-col gap-5 lg:gap-10'>
          <div className='flex flex-col lg:flex-row gap-5 lg:gap-10'>
          <ClientReview name="Krishna Patel" header="Incredible Results">
            My e-commerce store was struggling for sales, our revenue has been low lately and it got me very worried. I decided to give these guys a shot and wow, the money we make recently is nothing short of mind-bending
          </ClientReview>
          <ClientReview name="John Stut" header="One of the best web agency">
            Working with Joe's Studio was an absolute delight. They captured our vision flawlessly and transformed it into a captivating website that truly reflects our brand identity. Their creativity knows no limits!
          </ClientReview>
          <ClientReview name="Anna Nguyen" header="I love it">
            Joe's Studio is a master at their craft. They grasped our ideas with precision and skillfully crafted a website that left us in awe. The end result perfectly represents our brand, and we couldn't be happier!
          </ClientReview>
          </div>
          <div className='flex flex-col lg:flex-row gap-5 lg:gap-10'>
          <ClientReview name="Jesse Lee" header="Surpassed My Expectations">
          I had high expectations, but Joe's Studio surpassed them all. They are so dedicated to their craft, and it really shows in the website they designed for us. It's like a work of art!
          </ClientReview>
          <ClientReview name="Stefan K" header="A Pleasant Bunch">
          Collaborating with Joe's Studio was a breeze. They were patient and attentive to our needs, and the end result was beyond what we had imagined. Their creative approach set our website apart from the rest.
          </ClientReview>
          <ClientReview name="Dave Smith" header="Great Post-Sales">
            Their post-sales services are rock-solid. I have no needs for an in-house webmaster and my company's IT budget is now half the size. Good Stuff
          </ClientReview>
          </div>
        </div>
      </div>
  )
}
