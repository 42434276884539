import logo from '../Media/logo.svg';

function Navbar() {
    return (
        <div className="mt-0 fixed top-0 z-[999] w-screen border-b border-gray-950 backdrop-blur-md">
            <header className="text-2xl">
                <div
                    className="mx-auto flex h-14 md:h-20 items-center gap-5 px-5"
                >
                    <a className="block text-black-600" href="/">
                        <span className="sr-only">Home</span>
                        <img src={logo} alt="logo" className="h-14 md:h-20 invert"/>
                    </a>

                    <div className="flex flex-1 items-center justify-end md:justify-between">
                        <nav aria-label="Global" className=" md:block capitalize">
                            <ul className="flex items-center gap-4 md:gap-6 text-sm md:text-lg font-medium text-white">
                                <li className='hidden md:block'>
                                    <a href="/" className="  pb-1  hover:border-b border-blue-300 transition ease-in duration-3000">
                                        Home
                                    </a>
                                </li>

                                <li>
                                    <a href="/packages" className="  pb-1  hover:border-b border-blue-300 transition ease-in duration-3000">
                                        Packages
                                    </a>
                                </li>
                                <li>
                                    <a href="/about" className="  pb-1  hover:border-b border-blue-300 transition ease-in duration-3000">
                                        Our Studio
                                    </a>
                                </li>
                                <li className={"block md:hidden"}>
                                    <a href="/contact" className="opacity-75 hover:opacity-100 transition duration-300">
                                        Contact Us
                                    </a>
                                </li>
                            </ul>
                        </nav>

                        <div className="items-center gap-4 ">
                            <div className="hidden md:flex">
                                <a
                                    className=" text-center px-3 py-2 text-lg font-medium border border-blue-300"
                                    href="/contact"
                                >
                                    Contact Us
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    )
}

export default Navbar