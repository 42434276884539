import { AboutUsCarousel } from "./AboutUsCarousel"

function AboutUs() {
    return (
        <div className="mt-28 mx-9 lg:mx-20 xl:mx-48 2xl:mx-72">
            <h1 className="heading text-5xl text-center ">Our Studio</h1>
            <div className="mt-16 py-8">
                <h1 className="text-3xl font-semibold capitalize">
                    Custom made websites are not only for large business
                </h1>
                <div className="text-xl  mt-8 leading-relaxed">
                    Here at Joe's Studio, we firmly believe in delivering an exceptional website to every business – no exceptions. Just like a brick-and-mortar storefront portrays a business in the physical world, a website serves as the ultimate representation in the vast digital landscape.

                    Our mission is straightforward: to craft and refine websites that capture the very essence of your brand. We offer a comprehensive digital experience that seamlessly resonates with your audience.

                    Our team harmonizes creativity, functionality, and innovation to unlock your business's true potential in the online realm.

                    <br/> <br/>Whether big or small, we're here to debunk the myth that custom websites are reserved for the elite players. Every business deserves a distinctive online presence. Let's ditch the cookie-cutter approach and fashion a site that embodies "you." Affordable and remarkable – that's our style. Your business, your custom site – let's make it a reality!             </div>
            </div>
            <div className="mt-16 border-white p-0 md:py-8">
                <h1 className="text-3xl font-semibold capitalize mb-8">
                    From our customers
                </h1>
                <AboutUsCarousel></AboutUsCarousel>
            </div>
        </div>
    )
}

export default AboutUs