// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAEvSJoC4ar-eyOsN0jtoSh-4wFUYpFlu8",
  authDomain: "joesstudio-e70c6.firebaseapp.com",
  projectId: "joesstudio-e70c6",
  storageBucket: "joesstudio-e70c6.appspot.com",
  messagingSenderId: "89335237067",
  appId: "1:89335237067:web:e8b0ba9597f9454b724e4e",
  measurementId: "G-W2T6NC85D3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const firestore = getFirestore(app);

export{app,analytics,firestore}