import React from 'react';
import { firestore } from '../Firebase/config';
import { collection, addDoc } from 'firebase/firestore';


class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      message: ''
    }
  }
  async handleSubmit(e) {
    e.preventDefault();

    try {
      await addDoc(collection(firestore, "contacts"), {
        name: this.state.name,
        email: this.state.email,
        message: this.state.message,
        type: this.props.type == null ? 'general' : this.props.type,
        status: 0
      })
      alert("Message submitted")
      this.resetForm()
    } catch (e) {
      alert(e)
    }

  }
  resetForm() {
    this.setState({ name: '', email: '', message: '' })
  }
  render() {
    return (
      <div className={`flex-col border border-blue-300 text-white ${this.props.className} backdrop-blur-md`}>
        <div>
          <h1 className="text-4xl mb-8 heading">{this.props.header}</h1>
        </div>
        <form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST" className='text-lg'>
          <div className="form-group l">
            <input type="text" placeholder="Your Name" className="w-full mb-4 p-2 bg-transparent border border-gray-400 rounded-none" id="name" value={this.state.name} onChange={this.onNameChange.bind(this)} />
          </div>
          <div className="form-group">
            <input type="email" placeholder="Email" className="w-full mb-4 p-2 bg-transparent border border-gray-400 rounded-none" id="email" aria-describedby="emailHelp" value={this.state.email} onChange={this.onEmailChange.bind(this)} />
          </div>
          <div className="form-group">
            <textarea className="w-full mb-4 p-2 bg-transparent border border-gray-400 rounded-none" placeholder="Tell Us About Your Wonderful Idea" rows="5" id="message" value={this.state.message} onChange={this.onMessageChange.bind(this)} />
          </div>
        </form>
        <button type="submit" className="text-white  px-3 py-2 text-lg transition border border-blue-300 hover:bg-gray-900 rounded-none" onClick={this.handleSubmit.bind(this)}>Submit</button>
      </div>
    );
  }
  onNameChange(event) {
    this.setState({ name: event.target.value })
  }
  onEmailChange(event) {
    this.setState({ email: event.target.value })
  }
  onMessageChange(event) {
    this.setState({ message: event.target.value })
  }
}
export default Contact;